.wordLearningGrid {
  // padding: 2vmin 0; // for 4k
  font-family: cursive;
  height: 100%;
  transform-style: preserve-3d;

  // 字型 全域設定
  ruby {
    font-family: cursive;
    margin: 0.1vmin 0;

    rt {
      font-family: BopomofoRuby;
      font-size: 2.5vmin;
      font-weight: 700;
      width: 4vmin;
      transform: translateX(10px);
    }
  }

  // 內容 全域設定
  .article {
    // max-width: 100%;
    writing-mode: vertical-rl;
    direction: ltr;
    height: 100%;
    /* 針對國小高年級小視窗無注音的樣式設定 */
    &.chineseOnly {
      div {
        // 注音字型，標楷體
        margin: 0 0.3em;
        display: block;
        line-height: 1.5;

        &:last-of-type {
          margin-left: 0;
        }
      }
    }
    div {
      // 注音字型，標楷體
      margin: 0 0.3em;
      display: block;
      line-height: 2.1;

      &:last-of-type {
        margin-left: 0;
      }
    }
  }

  // 齊頭 全域設定
  .paragraph {
    display: flex;
    height: 100%;

    .content {
      line-height: 2.5;
      height: 100%;
      /* 針對國小高年級小視窗無注音的樣式設定 */
      &.chineseOnly {
        line-height: 0;
      }
    }

    // 開頭底色文字 全域設定
    .wordSense {
      margin-bottom: 2vmin;
      display: block;

      ruby {
        border-radius: 25px;
        color: #fff;
        background-color: #ff7192;
        padding: 0.2em 0.7em 0.2em 0.2em;

        /* 針對國小高年級小視窗無注音的樣式設定 */
        &.chineseOnly {
          padding: 0.1em 0em;
        }
      }

      &.blue {
        ruby {
          background-color: #53b2fa;
        }
      }
    }
  }

  // 開頭文字 全域設定
  .word {
    height: 100%;

    ruby {
      font-size: 1.5em;
      border-radius: 10px;
      background-color: #ffc0db;
      padding: 0.1em 0.5em 0.1em 0.2em;

      /* 針對國小高年級小視窗無注音的樣式設定 */
      &.chineseOnly {
        padding: 0.1em 0em;
      }
      rt {
        font-size: 3vmin;
      }
    }

    sign {
      font-size: 1.5em;
      background-color: #ffc0db;
      padding: 0.1em 0.5em;
      padding-left: 0.2em;
    }
  }

  /* 私名號 直線 全域設定 */
  .private_name {
    border-left: 3px solid transparent;
    border-image: url(./img/line.png) 30 7 repeat;

    &.red {
      border-image: url(./img/redLine.png) 30 7 repeat;
    }

    &.pink {
      border-image: url(./img/pinkLine.png) 30 7 repeat;
    }

    &.blue {
      border-image: url(./img/blueLine.png) 30 7 repeat;
    }
  }

  /* 私名號 波浪 全域設定 */
  .flow_wave {
    border-left: 7px solid transparent;
    border-image: url(./img/wave.png) 30 16 round;

    &.red {
      border-image: url(./img/redWave.png) 30 16 repeat;
    }

    &.pink {
      border-image: url(./img/pinkWave.png) 30 16 repeat;
    }

    &.blue {
      border-image: url(./img/blueWave.png) 30 16 repeat;
    }
  }

  // 字義教學
  &.MeaningSet {
    .article {
      .turnTitle {
        writing-mode: rl;
        display: flex;
        justify-content: flex-end;
        margin-right: 4.5vmin;
        font-family: Times New Roman;
        line-height: 1;
        margin-bottom: 1vmin;
      }

      /* 文字轉方向 */
      .turn {
        writing-mode: rl;
        font-family: Times New Roman;
      }

      .wordContent {
        // height: calc(100% - 10vmin);
        height: 100%;
      }

      .paragraph {
        .circle {
          border: 1px solid #000;
          border-radius: 50%;
          writing-mode: rl;
          width: 7vmin;
          height: 7vmin;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 0;
          font-size: 6vmin;
          font-family: Times New Roman;
        }

        .wordSenseContent {
          color: #0020f5;
        }
      }
    }
  }

  // 語詞教學
  &.WordsSet {
    .word {
      border-radius: 10px;

      ruby {
        margin: 0;
        border-radius: 0;

        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }

    .paragraph {
      .img {
        display: inline-block;
        width: 9vmin;
        margin: 20px 0;

        img {
          width: 100%;
          margin-left: 3vmin;
        }
      }
    }

    .wordSenseContent {
      display: block;
      margin: 0 2vmin 0 0;
    }
  }

  // 字音字形
  &.GlyphSet {
    .article {
      max-height: 68vmin;

      /* 針對國小高年級小視窗無注音的樣式設定 */
      &.chineseOnly {
        .word {
          margin: 0 2vmin;
        }
      }

      .word {
        margin: 0 5vmin;
      }

      /* 文字轉方向 */
      .turn {
        writing-mode: rl;
        display: flex;
        justify-content: flex-end;
        padding-right: 6vmin;
        line-height: 1;
        font-family: Times New Roman;
      }

      .numberParagraph {
        display: flex;

        .content {
          height: 100%;
          min-height: 0;
        }

        .wordSense {
          margin-bottom: 2vmin;

          ruby {
            border-radius: 25px;
            color: #fff;
            background-color: #ff7192;
            padding: 0.1em 0.6em 0.1em 0.5em;
            padding-left: 0.05em;
          }

          &.blue {
            ruby {
              background-color: #53b2fa;
            }
          }
        }

        .wordSenseContent {
          min-height: 0;
        }
      }

      .paragraph {
        display: flex;

        .wordRadical {
          display: flex;
          width: 18vmin;
          margin-left: 5vmin;

          /* 針對國小高年級小視窗無注音的樣式設定 */
          &.chineseOnly {
            margin-left: 0vmin;
          }
          img {
            width: 100%;
            height: auto;
            margin-right: 1vmin;
          }
        }

        .wordRadicalContent {
          line-height: 2.5;

          .wordSenseContent {
            min-height: 0;
          }
        }
      }

      & > .paragraph {
        height: 100%;
        display: block;
      }

      & > .numberParagraph {
        height: 100%;
      }
    }
  }

  // 生字成語
  &.IdiomSet {
    .article {
      .wordContent {
        display: flex;
        align-items: center;

        .youtube {
          a {
            img {
              width: 10vmin;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.small {
  font-size: 6vmin;

  ruby {
    rt {
      font-size: 1.6vmin;
    }
  }

  .word > * {
    font-size: 2vmin;
  }

  .circle {
    width: 5vmin;
    height: 5vmin;
    margin-right: 5vmin;
    font-size: 4vmin;
  }

  .wordRadical {
    width: 9vmin;
  }

  .wordAns {
    .turn {
      padding-right:0; // wordAns 內括號數字歪掉
    }
  }

  .turn {
    padding-right: 5vmin; 
  }

  .turnTitle {
    margin-right: 3.5vmin;
  }

  .private_name,
  .flow_wave {
    font-size: 6vmin !important;
  }

  .WordsSet {
    .paragraph {
      .img {
        width: 6vmin;

        img {
          width: 50%;
          margin: auto;
        }
      }
    }
  }
}

.medium {
  font-size: 8vmin !important;

  ruby {
    rt {
      font-size: 2.6vmin;
    }
  }

  .word > * {
    font-size: 3vmin;
  }

  .circle {
    width: 7vmin;
    height: 7vmin;
    margin-right: 6vmin;
    font-size: 6vmin;
  }

  .wordRadical {
    width: 13vmin;
  }

  .wordAns {
    .turn {
      padding-right:0;// wordAns 內括號數字歪掉
    }
  }

  .turn {
    padding-right: 6vmin; 
  }

  .turnTitle {
    margin-right: 4.5vmin;
  }

  .private_name,
  .flow_wave {
    // font-size: 8vmin !important;
  }

  .WordsSet {
    .paragraph {
      .img {
        width: 9vmin;

        img {
          width: 45%;
          margin: auto;
        }
      }
    }
  }
}

.large {
  font-size: 10vmin;

  .article {
    height: 100%;
  }

  ruby {
    rt {
      font-size: 3.6vmin;
    }
  }

  .word > * {
    font-size: 4vmin;
  }

  .circle {
    width: 10vmin;
    height: 10vmin;
    margin-right: 7vmin;
    font-size: 8vmin;
  }

  .wordRadical {
    width: 16vmin;
  }

  .wordAns {
    .turn {
      padding-right:0;// wordAns 內括號數字歪掉
    }
  }

  .turn {
    padding-right: 7vmin; 
  }

  .turnTitle {
    margin-right: 5.5vmin;
  }

  .private_name,
  .flow_wave {
    font-size: 10vmin !important;
  }

  .WordsSet {
    .paragraph {
      .img {
        width: 12vmin;

        img {
          width: 42%;
          margin: auto;
        }
      }
    }
  }
}

.Cloud_content {
  display: inline-block;

  .article,
  .paragraph {
    height: 100%;
  }
}

.File_content {
  // 離線版字義教學
  .MeaningSet {
    .article {
      height: 85%;
    }
  }
}

.Cloud_wordLearningContent {
  flex: 1 1 0;
  // display: block;
  display: flex;
  flex-direction: row;
  // writing-mode: vertical-rl;
  // direction: ltr;

  .wordLearningGrid {
    display: flex;

    &.flex-width {
      flex: 1 1 0;
      width: max-content;
    }

    .article {
      writing-mode: vertical-rl;
    }
  }

  .Cloud_imageBox {
    // flex-direction: row;

    .Cloud_prompt {
      writing-mode: rl;
    }
  }

  .MeaningSet {
    .article {
      .wordContent {
        height: 100%;
      }
    }
  }

  .GlyphSet {
    .article {
      & > .paragraph {
        height: 100%;
      }

      & > .numberParagraph {
        height: 100%;
      }
    }
  }
}

.File_wordLearningContent {
  flex: 1 1 0;
  display: flex;
  flex-direction: row;

  .wordLearningGrid {
    display: flex;

    &.flex-width {
      flex: 1 1 0;
      width: max-content;
    }
  }
}
