.aiQuestionContent {
  // display: flex;
  // align-items: center;
  padding: calc((12vmin / 900) * 100);
  background: #fff;
  border-radius: 12px;
  font-size: calc((24vmin / 900) * 100);
  font-weight: 700;
  > .title {
    color: #315D83;
    margin-bottom: .5rem;
  }
  > .step{
    display: flex;
    align-items: flex-start;
    margin-bottom: .4rem;
    color: #212B36;
    > .bullet {
      background: #315D83;
      color: #fff;
      border-radius: .3rem;
      padding: .3rem;
      white-space: nowrap;
      margin-right: .3rem;
      + div {
        padding: .3rem;
      }
    }
  }
}

.actionsButton{
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .8vmin;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 12px;
    cursor: pointer;
    & > a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.primaryButton {
  color: #7a310a;
  background: #ffa235;
  border-bottom: 5px solid #b7601a;
}

.secondaryButton {
  color: #212B36;
  background: #FFE1AD;
  border-bottom: 5px solid #FFA235;
}

@media screen and (max-height: 560px) {
  .actionsButton > * {
    width: 200px;
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 12px;
  }
}

